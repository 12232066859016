import React from "react";

// Style //
import "./TimeSheetTable.css";

// Components //
import Table from "react-bootstrap/Table";
import { TableRowLoader } from "../../components/index";
import {
  dateFormateFullMonth,
  dateFormate,
} from "../../../constents";

// Images //
import { icons } from "../../../assets/index";

// Hooks //
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { downloadTimeSheetBySite } from "../../../api/timeSheetApi";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsActions";

const TimeSheetTable = ({ timeSheetData, calculationData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.utils);
  const { userToken } = useSelector((state) => state.user);

  const timeSheetOtherStatus = {
    COMPLETED: "COMPLETED",
    TO_BE_COMPLETED: "TO BE COMPLETED",
  };

  const downloadTimesheet = async (id) => {
    try {
      dispatch(requestSent());
      const data = await downloadTimeSheetBySite(userToken, id);
      const fileurl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = fileurl;
      link.setAttribute('download', `timesheet.pdf`);
      document.body.appendChild(link);
      link.click();

      dispatch(responseRecived());

    } catch (error) {
      dispatch(receivedError(error));
      console.log(error);
      dispatch(responseRecived());
    }
  };

  return (
    <>
      {loading ? (
        <TableRowLoader limit={8} />
      ) : (
        <>
          <Table className="common-table" bordered>
            <thead>
              <tr>
                <th></th>
                <th>Pay Date</th>
                <th>Timesheet Period</th>
                <th>No. of Sub-Contractor</th>
                <th>Avg Weekly Pay</th>
                <th>Total Hours Worked</th>
                <th>Gross Pay</th>
                <th>Status</th>
                <th>View Timesheet</th>
                <th>Download Invoice From Subby Group</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {timeSheetData?.map((item, index) => {
                const splittedData = item.working_period_start.split('/')
                const recontractedDate = `${splittedData[2]}-${splittedData[1]}-${splittedData[0]}`
                const filteredTimesheets = calculationData.filter(cd => cd.working_period_start === recontractedDate);
                const is_freezed = filteredTimesheets.every(cd => cd.is_freezed);
                
                const grossAmount = filteredTimesheets.reduce((acc, val) => {
                  if (val.WorkUnit === 'AREA') {
                    return acc + (+val.adjusted_amount || +val.gross_amount)
                  } else {
                    return acc + ((+val.hourly_rate).toFixed(2) * (+val.total_hours).toFixed(2))
                  }
                }, 0);

                return (
                  <tr key={index}>
                    <td>
                      <img
                        className="table_icon"
                        src={icons?.timeSheet}
                        alt=""
                      />
                    </td>
                    <td>
                      {item?.last_date_of_pay_roll
                        ? dateFormateFullMonth(item?.last_date_of_pay_roll)
                        : "-"}
                    </td>
                    <td>
                      {dateFormate(item?.working_period_start)} -
                      {dateFormate(item?.working_period_end)}
                    </td>
                    <td>{item?.no_of_sole_traders}</td>
                    <td>{`£${(grossAmount / item.no_of_sole_traders).toFixed(2)}`}</td>
                    <td>{item?.total_hour_recorded.toFixed(2)}</td>
                    <td>£{grossAmount.toFixed(2)}</td>
                    <td
                      style={{
                        backgroundColor:
                          is_freezed
                            ? "#92D050"
                            : "#FFC001"
                      }}
                    >
                      {is_freezed ? timeSheetOtherStatus.COMPLETED : timeSheetOtherStatus.TO_BE_COMPLETED}
                    </td>

                    <td
                      onClick={() =>
                        navigate(
                          `/timesheets/timesheet-detail?site_id=${item.site_id}&status=${item.status}`,
                          {
                            state: {
                              workPeriod: ` ${item.working_period_start} - ${item.working_period_end}`,
                              timeSheetId: item.id,
                            },
                          }
                        )
                      }
                    >
                      <button>View Timesheet</button>
                    </td>
                    <td onClick={() => downloadTimesheet(item.id)}>
                      <button>Download</button>
                    </td>
                    <td></td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default TimeSheetTable;
