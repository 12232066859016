import React, { useEffect, useState } from "react";

// Style //
import "./SubContractorProfile.css";

// Components //
import {
  PageHeading,
  Loader,
} from "../../common/components/index";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

// Images //
import { icons } from "../../assets/index";
import { images } from "../../assets/index";

// Hooks //
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// API's //
import {
  getSoleTraderDetailsApi,
  downloadTicketAPi,
  downloadAllTicketAPi,
} from "../../api/soleTraderApi";

// Actions //
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsActions";

const SubContractorProfile = () => {
  const TABS = {
    TAB_ONE: "PERSONAL_DETAILS",
    TAB_TWO: "TICKETS",
    TAB_THREE: "DOCUMENTS",
  };

  // Hooks //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { loading } = useSelector((state) => state.utils);
  const { userToken, user } = useSelector((state) => state.user);

  const [tab, setTab] = useState(TABS.TAB_ONE);
  const [soleTraderDetails, setSoleTraderDetails] = useState(null);

  let soleTraderId = searchParams.get("id");

  // API Calls //
  const getSoleTradersDetails = async () => {
    try {
      dispatch(requestSent());
      const data = await getSoleTraderDetailsApi(userToken, soleTraderId);
      setSoleTraderDetails(data.data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("Sole Trader Details Error::::", error);
      dispatch(receivedError(error));
    }
  };

  const downloadTicket = async (ticket_id) => {
    try {
      dispatch(requestSent());
      const data = await downloadTicketAPi(userToken, { sole_trader_ticket_id: ticket_id, contractor_id: user.contractorData.id });

      const fileurl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = fileurl;
      link.setAttribute('download', `ticket.pdf`);
      document.body.appendChild(link);

      link.click();
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      console.log(error);
    }
  };

  const downloadAllTicket = async () => {
    try {
      dispatch(requestSent());
      const data = await downloadAllTicketAPi(userToken, { sole_trader_id: soleTraderDetails.id, contractor_id: user.contractorData.id });
      const fileurl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = fileurl;
      link.setAttribute('download', `ticket.pdf`);
      document.body.appendChild(link);

      link.click();
      dispatch(responseRecived());

    } catch (error) {
      dispatch(receivedError(error));
      console.log(error);
    }
  };

  // UseEffect //
  useEffect(() => {
    window.scrollTo(0, 0);
    getSoleTradersDetails();
  }, [soleTraderId]);

  // OnPage Components //
  const PresonalDetailsTab = () => {
    return (
      <div className="information_cont">
        <table className="information_cont_table">
          <tbody>
            <tr>
              <td>
                <h3>Personal Information</h3>
              </td>
            </tr>
            <tr>
              <td>Full Name</td>
              <td style={{ width: '40%' }}>
                {soleTraderDetails?.SoleTraderProfile?.firstname
                  ? `${soleTraderDetails?.SoleTraderProfile?.firstname} ${soleTraderDetails?.SoleTraderProfile?.surname} `
                  : "-"}
              </td>
            </tr>
            {
              soleTraderDetails?.SoleTraderProfile?.company_name && <tr>
                <td>Company Name</td>
                <td>{soleTraderDetails?.SoleTraderProfile?.company_name}</td>
              </tr>
            }
            {
              soleTraderDetails?.SoleTraderProfile?.company_registration_number && <tr>
                <td>Company Reg Number</td>
                <td>{soleTraderDetails?.SoleTraderProfile?.company_registration_number}</td>
              </tr>
            }
            <tr>
              <td>UTR</td>

              <td>
                {soleTraderDetails?.SoleTraderProfile?.utr
                  ? soleTraderDetails?.SoleTraderProfile?.utr
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>National Insurance</td>
              <td>
                {soleTraderDetails?.SoleTraderProfile?.nin
                  ? soleTraderDetails?.SoleTraderProfile?.nin
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Date Of Birth</td>
              <td>
                {soleTraderDetails?.SoleTraderProfile?.dob
                  ? soleTraderDetails?.SoleTraderProfile?.dob.split(" ")[0]
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="information_cont_table">
          <tbody>
            <tr>
              <td>
                <h3>Contact Information</h3>
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td style={{ width: '40%' }}>
                {soleTraderDetails?.email ? soleTraderDetails?.email : "-"}
              </td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>
                {soleTraderDetails?.SoleTraderProfile?.phone_number
                  ? ((soleTraderDetails?.SoleTraderProfile?.phone_number + "").startsWith('0') ? soleTraderDetails?.SoleTraderProfile?.phone_number : "0" + soleTraderDetails?.SoleTraderProfile?.phone_number)
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Country</td>
              <td>
                {soleTraderDetails?.SoleTraderProfile?.country
                  ? soleTraderDetails?.SoleTraderProfile?.country
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>City</td>
              <td>
                {soleTraderDetails?.SoleTraderProfile?.city
                  ? soleTraderDetails?.SoleTraderProfile?.city
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Post Code</td>
              <td>
                {soleTraderDetails?.SoleTraderProfile?.postcode
                  ? soleTraderDetails?.SoleTraderProfile?.postcode
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                {soleTraderDetails?.SoleTraderProfile?.address
                  ? soleTraderDetails?.SoleTraderProfile?.address
                  : (soleTraderDetails?.address || '-')}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="information_cont_table">
          <tbody>
            <tr>
              <td>
                <h3>Emergency Contact Information</h3>
              </td>
            </tr>
            <tr>
              <td>Next Of Kin</td>
              <td style={{ width: '40%' }}>
                {soleTraderDetails?.SoleTraderProfile?.emergency_name
                  ? soleTraderDetails?.SoleTraderProfile?.emergency_name
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Relationship</td>
              <td>
                {soleTraderDetails?.SoleTraderProfile?.emergency_relationship
                  ? soleTraderDetails?.SoleTraderProfile?.emergency_relationship
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>
                {soleTraderDetails?.SoleTraderProfile?.emergency_phone_number
                  ? ((soleTraderDetails?.SoleTraderProfile?.emergency_phone_number + "").startsWith('0') ? soleTraderDetails?.SoleTraderProfile?.emergency_phone_number : "0" + soleTraderDetails?.SoleTraderProfile?.emergency_phone_number)
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="information_cont_table">
          <tbody>
            <tr>
              <td>Medical Details</td>
              <td style={{ width: '40%' }}>
                {
                  soleTraderDetails?.MedicalQuestionnaire ? <a className="orange_btn" style={{ padding: '0.6rem', borderRadius: '0.3rem' }} href={`${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/download-medical-questionnaire/${soleTraderId}`}>Download</a> : 'N/A'
                }
              </td>
            </tr>
            <tr>
              <td>Passport & Driving Licence</td>
              <td style={{ width: '40%' }}>
                {
                  soleTraderDetails && soleTraderDetails.SoleTraderProfile?.passport_image ? <a className="orange_btn" style={{ padding: '0.6rem', borderRadius: '0.3rem' }} href={`${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/download-passport-and-driving-licence/${soleTraderId}`}>Download</a> : 'N/A'
                }
              </td>
            </tr>
          </tbody>
        </table>
        <table className="information_cont_table">
          <tbody>
            <tr>
              <td>
                <h3>Bank Information</h3>
              </td>
            </tr>
            <tr>
              <td>Bank Name</td>
              <td style={{ width: '40%' }}>
                {soleTraderDetails?.SoleTraderProfile?.bank_name
                  ? soleTraderDetails?.SoleTraderProfile?.bank_name
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Beneficiary</td>
              <td>
                {soleTraderDetails?.SoleTraderProfile?.account_name
                  ? soleTraderDetails?.SoleTraderProfile?.account_name
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Account Number </td>
              <td>
                {soleTraderDetails?.SoleTraderProfile?.account_number
                  ? soleTraderDetails?.SoleTraderProfile?.account_number
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Sort Code</td>
              <td>
                {soleTraderDetails?.SoleTraderProfile?.account_sort_code
                  ? soleTraderDetails?.SoleTraderProfile?.account_sort_code
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="information_cont_table">
          <tbody>
            <tr>
              <td>
                <h3>Other Information</h3>
              </td>
            </tr>
            <tr>
              <td>Rate of Pay</td>
              <td style={{ width: '40%' }}>
                {soleTraderDetails && soleTraderDetails.WorkUnit === "AREA" ? 'N/A' : `£${soleTraderDetails?.Contractors[0]?.daily_pay_rate || 0}`}
              </td>
            </tr>
            <tr>
              <td>Unit of Pay</td>
              <td>
                {soleTraderDetails?.WorkUnit &&
                  (soleTraderDetails.WorkUnit === "AREA"
                    ? "Measure"
                    : soleTraderDetails.WorkUnit === "DAYS"
                      ? "Day"
                      : soleTraderDetails.WorkUnit === "HOURS"
                        ? "Hour"
                        : "")}
              </td>
            </tr>
            <tr>
              <td>Timesheet</td>
              <td>{soleTraderDetails?.timesheet_completed_by}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const TicketsTab = () => {
    return (
      <Table className="common-table documents_table " bordered>
        <thead>
          <tr>
            <th>ITEM</th>
            <th></th>
            <th>EXPIRES</th>
            <th>
              <Button className="orange_btn" onClick={() => navigate('/sub-contractors/add-edit-contractor-tickets', { state: { sole_trader_id: soleTraderId, isEdit: false } })}>Add Ticket</Button>
            </th>
            <th>
              <Button className="orange_btn" onClick={downloadAllTicket}>Download All</Button>
            </th>
          </tr>
        </thead>
        
        <tbody>
          {soleTraderDetails?.SoleTraderTickets?.length ? soleTraderDetails.SoleTraderTickets.map((item, index) => {
            const originalDate = item?.expiry_date; // Assuming item is your object
            const formattedDate = new Date(originalDate).toLocaleDateString(
              "en-GB",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              }
            );
            return (
              <tr key={index}>
                <td>
                  <img className="table_icon" src={icons?.ticket} alt="" />
                  <b>{item?.Ticket?.name}</b>
                </td>
                <td></td>
                <td>{originalDate && (originalDate.includes('/') ? originalDate : formattedDate)}</td>
                <td>
                  <Button onClick={() => navigate('/sub-contractors/add-edit-contractor-tickets', { state: { sole_trader_id: soleTraderDetails.id, isEdit: true, data: item } })} className="orange_btn">Edit Ticket</Button>
                </td>
                <td>
                  {item.front_image ? (
                    <Button onClick={() => downloadTicket(item.id)} className="orange_btn">Download</Button>
                  ) : (
                    <i className="deseble_text">
                      <b>No Image</b>
                    </i>
                  )}
                </td>
              </tr>
            );
          }) : <div className="empty_data">
            <h3>{`${soleTraderDetails?.SoleTraderProfile?.firstname} ${soleTraderDetails?.SoleTraderProfile?.surname} Does not Have Any Ticket`}</h3>
          </div>}
        </tbody>
      </Table>
    );
  };

  const DocumentsTab = () => {
    return (
      <>
        {soleTraderDetails?.SoleTraderSiteDocuments.length === 0 ? (
          <div className="empty_data">
            <h1>🙂</h1>
            <h3>{`${soleTraderDetails?.SoleTraderProfile?.firstname} ${soleTraderDetails?.SoleTraderProfile?.surname} Does not Have Signed Any Documents`}</h3>
          </div>
        ) : (
          <>
            <Table className="common-table documents_table " bordered>
              <thead>
                <tr>
                  <th>COMPANY</th>
                  <th>SITE REFERENCE</th>
                  <th>DOCUMENT</th>
                  <th>
                    {/* <Button className="orange_btn">Download All</Button> */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {soleTraderDetails.SoleTraderSiteDocuments?.map((item) => (
                  <tr key={item.id}>
                    <td>{soleTraderDetails.Contractors[0].Company.name}</td>
                    <td>{item.ContractorSiteDocument.Site.reference}</td>
                    <td>
                      <img className="table_icon" src={icons.Documents} alt="" />
                      <b>{item.ContractorSiteDocument?.name}</b>
                    </td>
                    <td>
                      {item.ContractorSiteDocument?.image ? (
                        <button>
                          <a
                            target="blank"
                            href={`${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/site/documents/${item.ContractorSiteDocument?.image}`}
                          >
                            Download
                          </a>
                        </button>
                      ) : (
                        <i>
                          <b>There Are No PDF</b>
                        </i>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {
              soleTraderDetails?.SoleTraderSiteDocuments.length && <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                <a
                  target="blank"
                  href={`${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/download-sole-trader-contract/${soleTraderDetails.id}`}
                >
                  <Button className="orange_btn">
                    DOWNLOAD CONTRACT
                  </Button>
                </a>
              </div>
            }
          </>
        )}
      </>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div id="sub_contractor_profile">
      <PageHeading title={"Sub-Contractor Details"} />
      {soleTraderDetails?.status === "ACTIVE" ||
        soleTraderDetails?.status === "MISSINGDOCS" ||
        soleTraderDetails?.status === "INACTIVE" ? (
        <div className="right-sec_cont">
          <div className="profile_details_cont">
            <div className="header">
              <div className="profile_img_cont">
                <img
                  src={
                    soleTraderDetails.SoleTraderProfile.selfie_image
                      ? `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/sole_trader/onboarding/` + soleTraderDetails.SoleTraderProfile.selfie_image
                      : images.PersonPlaceHolder
                  }
                  alt=""
                />
              </div>
              <div className="profile_txt">
                <h3>
                  {`${soleTraderDetails?.SoleTraderProfile?.firstname} ${soleTraderDetails?.SoleTraderProfile?.surname}`}
                </h3>
                <div className="edit_field">
                  <p>{soleTraderDetails?.Contractors[0]?.Company?.name} </p>
                </div>
                <div className="tabs_cont">
                  <span
                    className={`${tab === TABS.TAB_ONE ? "active" : ""}`}
                    onClick={() => setTab(TABS.TAB_ONE)}
                  >
                    Personal Details
                  </span>
                  <span
                    className={`${tab === TABS.TAB_TWO ? "active" : ""}`}
                    onClick={() => setTab(TABS.TAB_TWO)}
                  >
                    Tickets
                  </span>
                  <span
                    className={`${tab === TABS.TAB_THREE ? "active" : ""}`}
                    onClick={() => setTab(TABS.TAB_THREE)}
                  >
                    Documents
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="tab_cont">
            {tab === TABS.TAB_ONE ? (
              <PresonalDetailsTab />
            ) : tab === TABS.TAB_TWO ? (
              <TicketsTab />
            ) : (
              <DocumentsTab />
            )}
          </div>
        </div>
      ) : (
        <PresonalDetailsTab />
      )}
    </div>
  );
};

export default SubContractorProfile;
